import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import ViewIndex from "./views/ViewIndex";

const App = () => {
    return <BrowserRouter>
        <Routes>
            <Route path="/:token" element={<ViewIndex />} />
        </Routes>
    </BrowserRouter>;
};

export default App;
