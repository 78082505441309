import * as React from 'react'

interface IProps {
    children?: React.ReactNode;
};

interface ISetWebTokenAction { type: "setWebToken", payload: string };
interface IClearWebTokenAction { type: "clearWebToken", payload: undefined };
interface ISetSaveState { type: "setSaveState", payload: SAVE_STATE };

type SAVE_STATE = "unsaved" | "denied" | "consent";

interface ISessionState {
    saveState: SAVE_STATE,
    webToken: string
};

type Actions = ISetWebTokenAction | IClearWebTokenAction | ISetSaveState;

const initialState: ISessionState = {
    saveState: "unsaved",
    webToken: ""
};

const reducer = (state: typeof initialState, action: Actions) => {
    switch (action.type) {
        case "setWebToken":
            return { ...state, webToken: action.payload };
        case "clearWebToken":
            return { ...state, webToken: "" };
        case "setSaveState":
            return { ...state, saveState: action.payload };
    }
};

const SessionContext = React.createContext(initialState as unknown as { state: ISessionState, dispatch: React.Dispatch<Actions> });

const SessionContextProvider = (props: IProps) => {
    const [state, dispatch] = React.useReducer(reducer, (initialState as never));
    const formViewerState = (state as ISessionState);
    return <SessionContext.Provider value={{ state: formViewerState, dispatch } as any}>
        {props.children}
    </SessionContext.Provider>
};

export { SessionContext, SessionContextProvider };
