import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { SessionContext, SessionContextProvider } from '../SessionContext';
import Title from '../SharedComponents/Title';
import ConsentForm from './ConsentForm';
import LoginPage from './LoginPage';

const Container = styled.div`
    background: #187fba;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 24px;
    flex: 1 1 auto;
`;

const ViewIndex = () => {
    const { token } = useParams<"token">();
    const sessionContext = React.useContext(SessionContext);
    const { saveState, webToken} = sessionContext.state;

    if (saveState !== "unsaved") {
        return <Container>
            {saveState === "consent" && <Title>Your option to share data has been saved.</Title>}
            {saveState === "denied" && <Title>Your option to <strong>not</strong> share data has been saved.</Title>}
        </Container>
    }
    if (webToken) {
        return <Container>
            <ConsentForm />
        </Container>;
    }
    return <Container>
        <LoginPage token={token} />
    </Container>;
};

const ContextView = () => <SessionContextProvider>
    <ViewIndex />
</SessionContextProvider>;

export default ContextView;