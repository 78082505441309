import styled, { keyframes } from "styled-components";

const rotateAnimation = keyframes`
0%{
    transform: rotate(0);
}
100%{
    transform: rotate(360deg);
}
`;

const spinAnimation = keyframes`
0% {
    stroke-dashoffset: 268.60617px;
    transform: rotate(0);
}

12.5% {
    stroke-dashoffset: 56.54867px;
    transform: rotate(0);
}

12.5001% {
    stroke-dashoffset: 56.54867px;
    transform: rotateX(180deg) rotate(72.5deg);
}


25% {
    stroke-dashoffset: 268.60617px;
    transform: rotateX(180deg) rotate(72.5deg);
}

25.0001% {
    stroke-dashoffset: 268.60617px;
    transform: rotate(270deg);
}

37.5% {
    stroke-dashoffset: 56.54867px;
    transform: rotate(270deg);
}

37.5001% {
    stroke-dashoffset: 56.54867px;
    transform: rotateX(180deg) rotate(161.5deg);
}

50% {
    stroke-dashoffset: 268.60617px;
    transform: rotateX(180deg) rotate(161.5deg);
}

50.0001% {
    stroke-dashoffset: 268.60617px;
    transform: rotate(180deg);
}

62.5% {
    stroke-dashoffset: 56.54867px;
    transform: rotate(180deg);
}

62.5001% {
    stroke-dashoffset: 56.54867px;
    transform: rotateX(180deg) rotate(251.5deg);
}

75% {
    stroke-dashoffset: 268.60617px;
    transform: rotateX(180deg) rotate(251.5deg);
}

75.0001% {
    stroke-dashoffset: 268.60617px;
    transform: rotate(90deg);
}

87.5% {
    stroke-dashoffset: 56.54867px;
    transform: rotate(90deg);
}

87.5001% {
    stroke-dashoffset: 56.54867px;
    transform: rotateX(180deg) rotate(341.5deg);
}

100% {
    stroke-dashoffset: 268.60617px;
    transform: rotateX(180deg) rotate(341.5deg);
}
`;  

const CircleBit = styled.circle`
    animation-name: ${spinAnimation};
    stroke-width: 10%;
    fill: transparent;
    transform-origin: center;
    transition: stroke-dashoffset 225ms linear;
    transition-property: stroke;
    animation-duration: 4s;
    animation-timing-function: cubic-bezier(.35,0,.25,1);
    animation-iteration-count: infinite;
    stroke: #ffffff;
    stroke-dasharray: 282.743px;
`;

const Picture = styled.svg`
    transform: rotate(-90deg);
    top: 0;
    left: 0;
    transform-origin: center;
    overflow: visible;
    width: 20px;
    height: 50px;
    animation: ${rotateAnimation} 2s linear infinite;
    margin: 6px 0;
`;

const LoadingIndicator = () => {
    return <Picture className="RadialLoadingIndicator" focusable={false} preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 100">
        <CircleBit cx="50%" cy="50%" r="45" />
    </Picture>;
}

export default LoadingIndicator;
