import React, { useContext } from 'react';
import styled from 'styled-components';
import { SessionContext } from '../SessionContext';
import LoadingIndicator from '../SharedComponents/LoadingIndicator';
import { attemptLogin } from './loginService';

const Title = styled.h2`
    margin: 0;
    font-size: 1.8rem;
    font-weight: 600;
`;

const LoginForm = styled.form`
    displayf: flex;
    flex-direction: column;
    & fieldset {
        display: flex;
        flex-direction: row;
        border: 0;
        padding: 0;
        margin: 0;
    }
    & input[type=number] {
        font-size: 1.3rem;
        padding: 8px;
        &:focus {
            outline: 3px solid #003087;
        }
    }
`;

const DayInput = styled.input`
    margin: 32px 8px 32px 0;
    width: 60px;
`;

const MonthInput = styled.input`
    margin: 32px 8px;
    width: 60px;
`;

const YearInput = styled.input`
    margin: 32px 0 32px 8px;
    width: 100px;
`;

const LoginButton = styled.input`
    cursor: pointer;
    background: #ffffff;
    color: #333333;
    border-radius: 6px;
    border: 0;
    font-size: 1.3rem;
    padding: 8px 12px;
`;

const ErrorContainer = styled.div`
    padding: 12px 24px;
`;

interface IProps {
    token?: string
};

const LoginPage = (props: IProps) => {
    const sessionContext = useContext(SessionContext);
    const {token} = props;
    const [day, setDay] = React.useState("");
    const [month, setMonth] = React.useState("");
    const [year, setYear] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    const updateField = (setter: (value: string) => void) => (e: React.SyntheticEvent<HTMLInputElement>) => {
        setter(e.currentTarget.value);
    };

    if (!token) {
        return <Title>Please provide a login token</Title>;
    }

    const loginSuccessCallback = (result: string) => {
        sessionContext.dispatch({ type: "setWebToken", payload: result });
    };

    const loginFailureCallback = (errorMessage: string) => {
        setLoading(false);
        setError(errorMessage);
    };

    const login = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        const date = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
        attemptLogin(token, date.getTime().toString(), loginSuccessCallback, loginFailureCallback);
    };

    return <div>
        <Title>Enter your date of birth</Title>
        <LoginForm onSubmit={login}>
            { loading && <LoadingIndicator />}
            <fieldset>
                <DayInput type="number" placeholder="dd" value={day} onChange={updateField(setDay)} />
                <MonthInput type="number" placeholder="mm" value={month} onChange={updateField(setMonth)} />
                <YearInput type="number" placeholder="yyyy" value={year} onChange={updateField(setYear)} />
            </fieldset>
            { error && <ErrorContainer>{error}</ErrorContainer>}
            <LoginButton type="submit" value="Log in" />
        </LoginForm>
    </div>;
};

export default LoginPage;
