import axios, { AxiosError, AxiosResponse } from "axios";
import { getAPIUrl } from '../config';

interface IError {
    message: string
};

export const saveConsent = (webToken: string, consent: number, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    // tslint:disable-next-line:no-console
    console.info("Posting ", webToken, consent);
    axios.post(
        `${getAPIUrl()}save`,
        `${consent}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "text/plain" } }
    )
        .then((response: AxiosResponse<string>) => {
            successCallback();
        })
        .catch((error: AxiosError<IError>) => {
            let errorMessage = "Unknown error";
            if (error.response) {
                errorMessage = error.response.data.message
            }
            failureCallback(errorMessage as string);
        });
};
