import axios, { AxiosError, AxiosResponse } from "axios";
import { getAPIUrl } from '../config';

interface IError {
    message: string
};

export const attemptLogin = (username: string, password: string, successCallback: (jwt: string) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}session`,
        {
            auth: {
                "password": password,
                "username": username
            }
        }
    )
    .then((response: AxiosResponse<string>) => {
        successCallback(response.data);
    })
    .catch((error: AxiosError<IError>) => {
        let errorMessage = "Unknown error";
        if (error.response && error.response.status === 401) {
            errorMessage = "Incorrect date of birth or token"
        } else if (error.response && error.response.status === 429) {
            errorMessage = "Timed out due to too many attempts.";
        } else if (error.response) {
            errorMessage = error.response.data.message
        }
        failureCallback(errorMessage as string);
    });
};
