import React, { useContext } from 'react';
import styled from 'styled-components';
import { SessionContext } from '../SessionContext';
import LoadingIndicator from '../SharedComponents/LoadingIndicator';

import { saveConsent } from './consentService';

const Title = styled.h2`
    margin: 0;
    font-size: 1.8rem;
    font-weight: 600;
`;

const InputForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.3rem;
    margin: 12px 0;
    & label {
        display: block;
        cursor: pointer; 
        user-select: none;
        text-align: left;
        margin: 6px 0;
        & +.radio {
            margin-top: 12px;
        }
        & input {
            display: none;
            &+span {
                display: inline-block; 
                position: relative;
                padding-left: 30px;
                &:before {
                    content: '';
                    display: block; 
                    position: absolute;
                    top: 5px;
                    left: 0;
                    border-radius: 50%;
                    margin-right: 5px;
                    width: 18px;
                    height: 18px;
                    border: 1px solid #ccc;
                    background: #fff;
                }
                &:after {
                    content: '';
                    display: block; 
                    width: 14px;
                    height: 14px;
                    background: #222;
                    position: absolute;
                    border-radius: 50%;
                    top: 8px;
                    left: 3px;
                    opacity: 0;
                    transform: scale(0,0); 
                    transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
                }
            }
            &:checked+span:after {
                opacity: 1;
                transform: scale(1, 1);
            }
        }
    }
`;

const SaveButton = styled.input`
    cursor: pointer;
    background: #ffffff;
    color: #333333;
    border-radius: 6px;
    border: 0;
    font-size: 1.3rem;
    padding: 8px 12px;
    margin: 12px 0 0;
`;

const ErrorContainer = styled.div`
    padding: 12px 24px;
`;

const ConsentForm = () => {
    const { dispatch, state } = React.useContext(SessionContext);
    const [consent, setConsent] = React.useState(false);
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const saveChanges = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        saveConsent(state.webToken, consent ? 1 : 3, () => {
            dispatch({ type: "setSaveState", payload: consent ? "consent" : "denied" });
            setLoading(false);
        }, (errorMessage: string) => {
            setError(errorMessage);
            setLoading(false);
        });
    };

    const updateConsent = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConsent(!consent);
    };

    return <div>
        {loading && <LoadingIndicator />}
        <ErrorContainer>{error}</ErrorContainer>
        <Title>Giving consent to share your health record</Title>
        <p>Information about your care and treatment at South West Yorkshire Partnership NHS Foundation Trust is held on an electronic health record. The same electronic record is used by other services involved in your care, including GP practices, clinics, hospitals and social care.</p>
        <p>Sharing your health record means that everyone who provides your care will have the information they need, when they need it. It helps us to work together and use the same information to make sure you get the best health and care services we can provide.</p>
        <p>Your health record will only be shared with services that provide you with care or treatment, and on a need-to-know basis. You can ask that some of your information is made ‘private’. This means it will not be seen by others on your ‘shared health record’.</p>

        <InputForm onSubmit={saveChanges}>
            <label htmlFor="yes-check">
                <input type="radio" name="consent" id="yes-check" checked={consent} onChange={updateConsent} />
                <span>If you are happy for us to share your health record with other health and social care organisations who provide you with care or treatment, please tick this box.</span>
            </label>
            <label htmlFor="no-check">
                <input type="radio" name="consent" id="no-check" checked={!consent} onChange={updateConsent} />
                <span>If you have changed your mind and do not want to share your health record with other health and social care organisations involved in your care please tick this box.</span>
            </label>
            <p>Note: choosing not to share your care record will not affect the care you receive from our Trust.</p>
            <SaveButton type="submit" value="Save" />
        </InputForm>
    </div>;
};

export default ConsentForm;